.ContractorCard {
  width: 100%;
  /* height: 526px; */
  border-radius: 4px;
  /* background: #fff; */
  /* border: 1px solid #e2e2e2; */
  font-size: 15px;
  color: #333;
  font-weight: 600;
  /* box-shadow: 0 12px 20px rgba(128, 128, 128, 0.28); */
  margin-top: 60px;
  padding: 20px;
  padding-bottom: 0;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .ContractorCard {
    margin-top: 10px;
  }
}

@media (max-width: 900px) {
  .ContractorCard {
    margin-top: 10px;
  }
}

@media (max-width: 800px) {
  .ContractorCard {
    width: 100%;
    height: 89vh;
    /* background: #70c55d; */
    margin: 0;
    border-radius: 0;
    margin: 0;
    text-align: center;
    font-size: 15px;
  }
}
@media (max-width: 688px) {
  .ContractorCard {
    width: 100%;
    padding: 0;

    /* background: #70c55d; */
  }
}

.ContractorCard .user-info {
  /* border-bottom: 1px solid #f0f0f0; */
  padding-top: 37px;
  padding-bottom: 16px;
  /* padding-left: 50px;
  padding-right: 50px; */
  text-align: center;
}

@media (max-width: 1024px) {
  .ContractorCard .user-info {
    padding: 0;
    padding-top: 10px !important;
    margin-bottom: 10px;
  }
}

@media (max-width: 900px) {
  .ContractorCard .user-info {
    padding: 0;
    padding-top: 10px !important;
    margin-bottom: 10px;
  }
}

@media (max-width: 800px) {
  .ContractorCard .user-info {
    padding: 0;
    /* margin-top: 400px; */
    margin-bottom: 10px;
  }
}

@media (max-width: 688px) {
  .ContractorCard .user-info {
    padding: 0;
    padding-top: 10px !important;
    margin-bottom: 10px;
  }
}

.ContractorCard h4 {
  padding: 0;
  margin: 0;
  font-weight: 300;
}

.ContractorCard .user-info .active-user {
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 50px;
  width: 94px;
  height: 94px;
}

.ContractorCard .username {
  font-size: 22px;
  color: #0a1004;
  font-weight: 700;
  margin-bottom: 8px;
}

@media (max-width: 800px) {
  .ContractorCard .contractor-handle {
    font-size: 13px;
    color: #0a1004;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
@media (max-width: 688px) {
  .ContractorCard .contractor-handle {
    font-size: 13px;
    color: #0a1004;
    font-weight: 700;
    margin-bottom: 80px;
  }
}

@media (max-width: 800px) {
  .ContractorCard .username {
    font-size: 16px;
  }
}

.ContractorCard .edit-photo {
  background: none;
  border: 0;
  color: #808080;
  cursor: pointer;
  outline: none;
  font-weight: 300;
  font-size: 14px;
  transition: all 0.1s ease-in-out;
}

@media (max-width: 800px) {
  .ContractorCard .edit-photo img {
    width: 48px;
    height: 48px;
  }
}

.ContractorCard .edit-photo:hover {
  color: #538f40;
  font-weight: 600;
}

.ContractorCard .tabs {
  height: 306px;
  width: 100%;
}

@media (max-width: 688px) {
  .ContractorCard .tabs {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px solid #70c55d; */
    height: 40px;
    border-radius: 4px;
    justify-content: center;
    /* padding-top: 8px;
    padding-bottom: 8px; */
  }
  .ContractorCard .tabs button {
    height: 100%;
  }
}

@media (max-width: 800px) {
  .ContractorCard .tabs {
    margin: 0 auto;

    height: 40px;
    border-radius: 4px;
    justify-content: center;
    /* padding-top: 8px;
    padding-bottom: 8px; */
  }
  .ContractorCard .tabs button {
    height: 100%;
  }
}

.ContractorCard .tabs .tab-top {
  width: 100%;
  height: 70px;
  /* border-bottom: 1px solid #f0f0f0; */
  padding: 12px 28px;
  text-align: center;
}

.ContractorCard .tabs .tab-top .action-button {
  /* width: 100%;
  height: 46px;
  padding: 0px 60px;
  border-radius: 4px;
  background: #70c55d;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  border: none;
  transition: all 0.2s;
  box-shadow: 0 12px 20px rgba(105, 118, 107, 0.28);
  text-align: center;
  vertical-align: middle;
  outline: none;
  cursor: pointer; */
}
.ContractorCard .tabs .tab .icon {
  margin-right: 15px;
  color: #0e2113;
  opacity: 0.8;
}

.ContractorCard .tabs .tab-top .action-button:hover {
  background: rgb(90, 181, 70);
}

.ContractorCard .tabs .tab {
  width: 100%;
  height: 58px;
  text-align: center;
  /* border-bottom: 1px solid #f0f0f0; */
}

@media (max-width: 800px) {
  .ContractorCard .tabs .tab {
    height: 40px;
    width: 70%;
    margin-bottom: 10px;
    border-radius: 4px;
  }
}

@media (max-width: 688px) {
  .ContractorCard .tabs .tab {
    height: 40px;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
}

.ContractorCard .tabs .tab a button {
  width: 70%;
  align-items: center;
  text-decoration: none;
  color: #373737;
  font-weight: 300;
  border: 0;
  background: none;
  outline: none;
  margin-bottom: 10px;
  border-radius: 4px;
}

@media (max-width: 688px) {
  .ContractorCard .tabs .tab a button {
    width: 161px;
    height: 40px;
    background: none;
    border: 0;
    border-radius: 4px;
    transition: all 0;
    padding: 0;
    border: 1px solid #70c55d;
  }
}

.ContractorCard .tabs .tab a.active button {
  width: 70%;
  height: 46px;
  /* padding: 12px 40px; */
  border-radius: 25px;
  background: #70c55d;
  font-size: 16px;
  font-weight: 500;
  color: #f7f7f7;
  text-decoration: none;
  border: none;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}

@media (max-width: 800px) {
  .ContractorCard .tabs .tab a.active button {
    height: 34px;
    width: 100%;
  }
}

@media (max-width: 688px) {
  .ContractorCard .tabs .tab a.active button {
    width: 161px;
    height: 100%;
    border-radius: 0;
    background: #70c55d;
    outline: none;
    color: #fff;
    /* padding: 10px 40px; */
    border-radius: 4px;
  }
}

.ContractorCard .tabs .tab a.active button:focus {
  outline: none;
}

.ContractorCard .tabs .tab a.active button:hover {
  color: #fff;
}

.ContractorCard .tabs .tab a button:hover {
  /* color: rgb(90, 181, 70); */
}

.ContractorCard .tabs .tab:nth-child(5) {
  border-bottom: none;
}

.ContractorCard .contractor-handle {
  color: #13171276;
  font-weight: 700;
}

.transfer-btn {
  border: none;
  background: none;
  outline: none;
}
