.Onboarding {
  width: 90%;
  margin: 0 auto;
  margin-top: 120px;
  font-size: 18px;
}

.Onboarding h2 {
  font-size: 32px;
  font-weight: 700;
}

.Onboarding h2 span {
  color: #71c55d;
}

.Onboarding .accounts .choose {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.Onboarding .accounts .choose button {
  width: 547px;
  height: 603px;
  border-radius: 4px;
  border: 0;
  background: #fff;
  margin-right: 100px;
  box-shadow: 0 12px 20px rgba(226, 226, 226, 0.54);
  outline: none;
}

.homeowner-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 94px;
}

.contractor-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 92px;
  margin-bottom: 60px;
  transition: all 0.2s;
}

.Onboarding .accounts .choose button:hover {
  border: 2px solid #3f9b16;
  outline: none;
}

.Onboarding .accounts .choose button:focus {
  border: 2px solid #3f9b16;
  outline: none;
}

.Onboarding .accounts .choose .homeowner {
  text-align: center;
}

.Onboarding .accounts .choose .homeowner p {
  font-size: 22px;
  margin-top: 42px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1f221c;
}

.Onboarding .accounts .choose .contractor {
  text-align: center;
}

.Onboarding .accounts .choose .contractor p {
  font-size: 22px;
  margin-top: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1f221c;
}
