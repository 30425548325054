.Project {
  width: 90%;
  height: 375px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 20px rgba(128, 128, 128, 0.28);
  margin-bottom: 34px;
}

.ContractorProfile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 40px;
  margin-top: 40px; */

  margin: 40px auto;
  padding: 40px 0;
  border-radius: 4px;
  font-size: 16px;
  text-align: left;
  background: #fff;
  box-shadow: 0 12px 20px rgba(226, 226, 226, 0.54);
}

.ContractorProfile .Contractor-info {
  width: 60%;
}

.ContractorProfile i {
  margin-right: 8px;
  color: #71c55d;
}

@media (max-width: 600px) {
  .ContractorProfile {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .ContractorProfile {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  .ContractorProfile {
    width: 90%;
  }
}

.ContractorProfile .ContractorImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  background: url('../../img/default-avatar.png') no-repeat center center/cover;
  border-radius: 50%;
  margin-bottom: 30px;
}

.ContractorProfile .ContractorImage img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: none;
  border-radius: 50%;
}

.Project .project-content {
  width: 70%;
  padding: 84px 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Project .project-content .project-info {
  color: #464646;
}

.Project .project-content .project-info h2 {
  font-size: 28px;
  color: #000;
  font-weight: 700;
}

.Project .project-content .project-info i {
  margin-right: 13px;
}

.Project .project-content .description {
  width: 100%;
  border-top: 1px solid #f0f0f0;
  color: #686868;
  font-weight: 300;
  line-height: 1.5;
}
.bid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 20px;
}

.Bid {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 20px rgba(128, 128, 128, 0.28);
  margin-bottom: 30px;
  width: 90%;
}

.back button {
  margin-bottom: 20px;
  background: #71c55d;
  width: 150px;
  height: 42px;
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  /* margin-left: px; */
}

@media (max-width: 600px) {
  .back button {
    margin-left: 5%;
  }
}

/* @media (max-width: 800px) {
  .back button {
    margin-left: 5%;
  }
} */

@media (max-width: 1024px) {
  .back button {
    margin-left: 5%;
  }
}

.back button:hover {
  color: #fff;
  background: #4a9138;
  text-decoration: none;
}

button.StripeCheckout {
  background: #71c55d !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 4px !important;
  transition: all 0.3s;
  height: 40px !important;
  line-height: 32px !important;
}

button.StripeCheckout:hover {
  background: #4e943c !important;
  transform: scale(1.1);
}

button.StripeCheckout span {
  color: #fff !important;
  background-image: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  text-transform: uppercase !important;
  font-family: 'Asap', sans-serif !important;
  font-weight: 500 !important;
}

button.StripeCheckout span:hover {
  color: #fff !important;
}

button {
  outline: none;
}

button:focus {
  outline: none;
}

a {
  outline: none;
}

a:focus {
  outline: none;
}
