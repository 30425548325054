.project-container {
  margin-top: 100px;
  width: 100%;
}

@media (max-width: 1024px) {
  .project-container {
    margin-top: 30px;
  }
}

@media (max-width: 900px) {
  .project-container {
    margin-top: 30px;
  }
}

.project-container h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 24px;
  font-weight: 300;
}

.project-container h3 {
  margin: 0;
  color: #538f40;
  font-size: 32px;
  font-weight: 500;
}

.project-container .message {
  font-size: 18px;
  opacity: 0.8;
}

.content {
  margin-top: 23px;
  /* background: #fff;
  box-shadow: 0 0 10px #020c1d29; */
  border-radius: 8px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #dcdcdc;
  font-weight: 300;
}

.empty {
  margin-top: 23px;
  /* background: #fff;
  box-shadow: 0 0 10px #020c1d29; */
  border-radius: 8px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  font-weight: 300;
}

.project-container .action-button {
  margin-top: 20px;
  width: 60px;
  height: 60px;
  padding: 7px;
  border-radius: 50px;
  background: #e2eadf;
  font-size: 13px;
  font-weight: 700;
  color: #538f40;
  text-decoration: none;
  border: none;
  transition: all 0.2s;
  /* box-shadow: 0 0 10px #01060e45; */
  outline: none;
  cursor: pointer;
}

.project-container .action-button:hover {
  transform: scale(1.1);
  background: #538f40;
  color: #fff;
}
