* {
  box-sizing: border-box;
}

/* html {
  font-size: 62.5%;
} */

body {
  margin: 0 auto;
  /* max-width: 1200px; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Asap', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  /* background: #f5f7f9; */
  /* font-size: 1.6rem; */
  color: #020c1d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
