.App {
  /* justify-content: center; */
}

.container {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .container {
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 900px) {
  .container {
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .container {
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
}
