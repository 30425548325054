.AddProject {
  margin-top: 25px;
  padding: 56px 63px;
  margin-bottom: 60px;
  /* height: 690px; */

  background: #fff;
  box-shadow: 0 12px 20px rgba(226, 226, 226, 0.54);
  border-radius: 4px;
}

@media (max-width: 800px) {
  .AddProject {
    padding: 8% 10%;
    margin-top: 0 !important;
  }
}

.Add {
  margin-top: 100px;
}

@media (max-width: 800px) {
  .Add {
    margin-top: 30px;
  }
}

.Add h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 24px;
  font-weight: 300;
}

.AddProject form .form-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.AddProject form .form-items .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.AddProject form .form-items .form-item label {
  font-size: 14px;
  margin-bottom: 8px;
  padding: 0px;
  color: #70c55d;
  font-weight: 500;
}

.AddProject form .form-items .form-item input {
  width: 100%;
  height: 52px;
  border: 0;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 8px;
  font-size: 16px;
  transition: all 0.5s;
}

.AddProject form .form-items .form-item input:focus {
  border-bottom: 2px solid #70c55d;
  outline: none;
}

.AddProject form .form-items .form-item input::placeholder {
  color: #c1c1c1;
}

.AddProject form .form-items .core-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 800px) {
  .AddProject form .form-items .core-info {
    flex-direction: column;
  }
}

.AddProject form .form-items .core-info .radio .form-item-radio label {
  font-size: 14px;
  margin-bottom: 16px;
  padding: 0px;
  color: #70c55d;
  font-weight: 500;
}

.AddProject form .form-items .core-info .radio {
  width: 35%;
}

@media (max-width: 800px) {
  .AddProject form .form-items .core-info .radio {
    width: 100%;
    height: 80px;
  }
}

.AddProject form .form-items .core-info .radio .checks {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.AddProject form input[type='radio'] {
  margin-right: 12px;
  /* border: 1px solid #e2e2e2 !important; */
  border: 0 !important;
}

.AddProject form .form-items .core-info .radio .checks .form-item-check label {
  color: rgb(79, 79, 79);
  font-size: 14px;
  margin-bottom: 25px;
}
.AddProject form .form-items .core-info .radio .checks .form-item-check {
  color: rgb(79, 79, 79);
  font-size: 14px;
  margin-bottom: 25px;
  margin-right: 30px;
}

.AddProject select {
  border: 1px solid #e2e2e2;
  background: none;
  height: 32px;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

.AddProject form .form-items .core-info .budget {
  width: 48%;
}

@media (max-width: 800px) {
  .AddProject form .form-items .core-info .budget {
    width: 100%;
  }
}

.AddProject form .form-items .form-item textarea {
  width: 100%;
  height: 120px;
  /* margin-top: 10px; */
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  padding: 12px;
  font-size: 16px;
  transition: all 0.5s;
}

.AddProject form .form-items .form-item textarea:focus {
  border: 2px solid #70c55d;
  outline: none;
}

.AddProject form .form-items .form-item textarea::placeholder {
  color: #c1c1c1;
}

.AddProject form .add-project {
  height: 50px;
  width: 180px;
  border: 0;
  border-radius: 4px;
  background: #70c55d;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  /* box-shadow: 0 12px 20px rgba(105, 118, 107, 0.28); */
  transition: all 0.2s ease-in-out;
}

.AddProject form .add-project:hover {
  background: rgb(90, 181, 70);
}

.AddProject form .add-project:focus {
  outline: none;
}

.disable {
  height: 50px;
  width: 180px;
  border: 0;
  border-radius: 4px;
  background: #70c55d;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.3;
  cursor: not-allowed !important;
  outline: none;
}

.disable:focus {
  outline: none;
}

.AddProject form .control {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.AddProject form .control .add-photos {
  display: flex;
  width: 160px;
  justify-content: space-between;
  align-items: baseline;
}

.AddProject form .upload {
  background: #e2eadf;
  color: #70c55d;
  border-radius: 25px;
  border: 0;
  width: 42px;
  height: 42px;
  text-decoration: none;
  outline: none;
  transition: all 0.3s;
}

.AddProject form .upload:hover {
  transform: scale(1.1);
}

.AddProject .character-counter {
  font-size: 13px;
  opacity: 0.4;
  margin-top: 8px;
  text-align: right;
}
