.ContractorProject {
  max-width: 90%;
  /* height: 244px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background: #fff;
  border-radius: 4px;
  /* box-shadow: 0 12px 20px rgba(128, 128, 128, 0.28); */
  /* border: 1px solid #e2e2e2; */
  margin-bottom: 34px;
  box-shadow: 0 12px 20px rgba(226, 226, 226, 0.54);
}

@media (max-width: 800px) {
  .ContractorProject {
    max-width: 100%;
  }
}

.ContractorProject .contractor-project-image {
  width: 100%;
  height: 218px;
  background: url('../../img/Placeholder-image.png') no-repeat center
    center/cover;
  border-radius: 4px 4px 0 0;
  display: inline-block;
  position: relative;
}

.ContractorProject .contractor-project-image img {
  width: 100%;
  height: 100%;
  border: none;
  object-position: top;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.ContractorProject .contractor-project-image:hover img {
  filter: brightness(60%);
}

.ContractorProject .contractor-project-image p {
  z-index: 0;
  width: 100%;
  position: absolute;
  text-align: center;
  transition: all 0.3s;
  opacity: 0;
  font-size: 18px;
  text-transform: uppercase;
}

.ContractorProject .contractor-project-image:hover p {
  z-index: 1;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 700;
  opacity: 1;
}

.ContractorProject .contractor-project-image .image-label {
  top: 50%;
}

.ContractorProject .contractor-project-content {
  width: 100%;
  padding: 22px 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .ContractorProject .contractor-project-content {
    padding: 22px 5%;
  }
}

@media (max-width: 900px) {
  .ContractorProject .contractor-project-content {
    padding: 22px 5%;
  }
}

@media (max-width: 800px) {
  .ContractorProject .contractor-project-content {
    padding: 22px 5%;
  }
}

@media (max-width: 688px) {
  .ContractorProject .contractor-project-content {
    padding: 22px 5%;
  }
}

.ContractorProject .contractor-project-content h2 {
  margin: 0;
  font-size: 24px;
  color: #464646;
  font-weight: 700;
  margin-bottom: 16px;
}

.ContractorProject .contractor-project-content .c-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.ContractorProject .posted-by {
  font-size: 14px;
}

.ContractorProject .posted-by .project-username {
  font-size: 14px;
  font-weight: 700;
  color: #252924;
}

.ContractorProject .contractor-project-content .c-info i {
  margin-right: 6px;
  color: #70c55d;
}

.ContractorProject .contractor-project-content .c-info p {
  font-size: 14px;
  margin: 0;
  margin-right: 60px;
}

@media (max-width: 688px) {
  .ContractorProject .contractor-project-content .c-info p {
    margin-right: 16px !important;
  }
}

@media (max-width: 800px) {
  .ContractorProject .contractor-project-content .c-info p {
    margin-right: 14px !important;
  }
}

.ContractorProject .contractor-project-content .contractor-description {
  width: 100%;
  color: #686868;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ContractorProject .contractor-project-content .contractor-description p {
  font-size: 16px;
  margin: 0;
}

.ContractorProject .timestamp {
  font-size: 13px;
  color: #8d9b88;
  font-weight: 500;
}
.ContractorProject .image-modal {
  width: 100%;
  border: 0;
  background: none;
  padding: 0;
}

.ContractorProject .contractor-project-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
}

.ContractorProject button {
  outline: none;
}

.place-bid {
  background: #70c55d;
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.place-bid i {
  margin-right: 8px;
}

.place-bid:hover {
  background: rgb(90, 181, 70);
  font-weight: 500;
  transform: scale(1.1);
}

.ContractorProject .contractor-project-category {
  font-size: 14px;
}

.ReactModal__Content--after-open {
  background-color: rgba(0, 0, 0, 0.489) !important;
}

.ril__toolbar {
  display: none;
}
