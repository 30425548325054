.EditProfile {
  margin-top: 25px;
  margin-bottom: 40px;
  padding: 56px 63px;
  /* height: 690px; */

  background: #fff;
  box-shadow: 0 12px 20px rgba(226, 226, 226, 0.54);
  border-radius: 4px;
}

.Edit {
  margin-top: 100px;
}

@media (max-width: 688px) {
  .EditProfile {
    width: 100%;
  }
}

@media (max-width: 688px) {
  .Edit {
    margin-top: 0;
  }
}

@media (max-width: 800px) {
  .Edit {
    margin-top: 30px;
  }
}

.Edit h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 24px;
  font-weight: 300;
}

.EditProfile form .user-form-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.EditProfile .user-form-content .name {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 688px) {
  .EditProfile .user-form-content .name {
    flex-direction: column;
  }
}

.EditProfile .user-form-content .name .first-name {
  width: 48%;
}

@media (max-width: 688px) {
  .EditProfile .user-form-content .name .first-name {
    width: 100%;
  }
}

.EditProfile .user-form-content .name .last-name {
  width: 48%;
}

@media (max-width: 688px) {
  .EditProfile .user-form-content .name .last-name {
    width: 100%;
  }
}

.EditProfile form .user-form-content .user-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.EditProfile form .user-form-content .user-form-item label {
  font-size: 14px;
  margin-bottom: 8px;
  padding: 0px;
  color: #70c55d;
  font-weight: 500;
}

.EditProfile form .user-form-content .user-form-item input {
  width: 100%;
  height: 52px;
  border: 0;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 8px;
  font-size: 16px;
  transition: all 0.5s;
}

.EditProfile form .user-form-content .user-form-item input:focus {
  border-bottom: 1px solid #70c55d;
  outline: none;
}

.EditProfile form .user-form-content .user-form-item input::placeholder {
  color: #c1c1c1;
}

.EditProfile form .photo {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.EditProfile form .profile-image {
  background: #e2eadf;
  color: #70c55d;
  border-radius: 25px;
  border: 0;
  width: 42px;
  height: 42px;
  text-decoration: none;
  outline: none;
  transition: all 0.3s;
  margin-right: 16px;
  transition: all 0.3s;
}

.EditProfile form .profile-image:hover {
  transform: scale(1.1);
}

.EditProfile form .edit-profile {
  height: 50px;
  width: 180px;
  border: 0;
  border-radius: 4px;
  background: #70c55d;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  /* box-shadow: 0 12px 20px rgba(105, 118, 107, 0.28); */
  transition: all 0.2s ease-in-out;
  outline: none;
}

.EditProfile form .edit-profile:hover {
  background: rgb(90, 181, 70);
}

.EditProfile form .form-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.EditProfile form .form-footer .user-form-item {
  margin-bottom: 0;
}
